//Color Palette
$primary-blue: #0099dd;
$button-border-blue: #0070c9;
$button-border-green: #24a82c;
$button-border-red: #cc2505;
$button-border-yellow: #c48f2d;
$primary-purewhite : #fff;
$primary-white : #f8f9fb;
$primary-offwhite : #f5f5f5;
$primary-black  : #484848; 
$primary-grey   : #777777;
$primary-lightgrey : #ccc;
$primary-lightergrey : #e2e7eb;
$primary-green: #33cb33;
$secondary-green: #36a749;
$secondary-red: #e32b2b;
$secondary-yellow: #eca01f;
$primary-white-gradient: linear-gradient(to top, $primary-lightgrey, $primary-white);
$primary-blue-gradient: linear-gradient(to top, #007db2, #0097d8);
$primary-blue-gradient-reverese: linear-gradient(to top, #0097d8, #007db2);
$primary-green-gradient: linear-gradient(to top, #24a82c, #59cb5f);
$primary-red-gradient: linear-gradient(to top, #cc2505, #ec5c41);
$primary-yellow-gradient: linear-gradient(to top, #c48f2d, #f3b037);
$button-hover-blue: #007db2;
$button-hover-green: #24a82c;
$button-hover-red: #cc2505;
$button-hover-yellow: #c48f2d;

//Social Colors
$social-fb: #3b5998;
$social-twitter: #000000;
$social-instagram: linear-gradient(90deg,#feda75 0,#fa7e1e 25%,#d62976 50%,#962fbf 75%,#4f5bd5);
$social-linkedin: #0077b5;

//Borders
$border: 1px solid $primary-lightgrey;
$box-shadow: 0 4px 8px 0 $primary-lightgrey;
$modal-shadow: 0 0 8px 0 $primary-black;
$input-shadow: inset 0 0 2px 0 $primary-lightgrey;
$inset-shadow: inset 0 0 4px 0 $primary-lightgrey;

//Fonts
$font-family: 'Open Sans', Helvetica, Arial, sans-serif;
$font-XL: 32px;
$font-L: 24px;
$font-M: 18px;
$font-S: 14px;
$font-XS: 12px;
$font-letter-spacing: 0.5px;
$font-weight-thin: 300;
$font-weight-regular: 400;
$font-weight-bold: 600;
$font-line-height-regular: 24px;

//Breakpoints
$break-mobile-max: 767px;
$break-tablet-min: 768px;
$break-tablet-max: 1023px;
$break-medium-min: 1024px;
$break-medium-max: 1199px;
$break-large-min: 1200px;