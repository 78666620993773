@import "../settings/variables";

* {
    margin: 0;
    padding: 0;
}

::-webkit-input-placeholder { color: $primary-lightgrey; }
::-moz-placeholder { color: $primary-lightgrey; }
:-ms-input-placeholder { color: $primary-lightgrey; }
:-moz-placeholder { color: $primary-lightgrey; }
:focus { outline: none; }

body {
    font-family: $font-family;
    background-color: $primary-purewhite;
    letter-spacing: $font-letter-spacing;
}
main{
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    &.has-background:before{
        position: absolute;
        content: "";
        position: absolute;
        top: 0; left: 0;
        width: 100%; height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
    }
}

em{ font-style: italic; }
strong{ font-weight: 600; }
h1, h2, h3, h4, h5{
    color: $primary-black;
    font-weight: 400;
    margin-bottom: 30px;

    &.blue, span{ color: $primary-blue; }
    &.red{ color: $secondary-red; }
    &.yellow{ color: $secondary-yellow; }
    &.green{ color: $secondary-green; }
    small{ 
        display: block;
        color: $primary-grey;
        margin-top: 16px;
    }
}

h1{
    font-size: $font-XL;

    small{
        margin-top: 16px;
        font-size: $font-M;
    }
}

h2{
    font-size: $font-L;
    
    small{ 
        margin-top: 16px;
        font-size: $font-S; 
    }
}

h3{
    font-size: $font-M;
    line-height: $font-line-height-regular;
    
    small{ font-size: $font-S; }
}

h4{
    font-size: $font-S;
    line-height: $font-line-height-regular;
    
    small{ font-size: $font-XS; }
}

h5{
    font-size: $font-XS;
    
    small{ font-size: $font-XS; }
}

p{
    color: $primary-grey;
    font-size: $font-S;
    line-height: 20px;
    
    small{ font-size: $font-XS; }
    &.blue, span{ color: $primary-blue; }
    strong{
        color: $primary-black;
        font-weight: $font-weight-bold;
    }
}

p, p > small, a, h5{ line-height: $font-line-height-regular; }

a {
    color: $primary-blue;
    cursor: pointer;
    font-size: inherit;
    font-weight: 800;
    text-decoration: none;
}

// table {
//     width: 75%;
//     font-size: $font-XS;
//     color: $primary-grey;
// }
// td {
//     height: 25px;
// }

sup{
    vertical-align: super;
    font-size: 0.75em;
}

label{
    display: block;
    color: $primary-black;
    font-size: $font-S;
    
    &.blue, span{ color: $primary-blue; }
}

hr{
    margin: 16px 0;
    border: 0;
    border-top: $border;
}

ul{
    padding-left: 24px;
    text-align: left;

    li{
        list-style: disc;
        color: $primary-grey; 
        font-size: $font-XS;
        line-height: $font-line-height-regular;
    }
    &.two-col{
        @include flex(justify, space-between);
        flex-wrap: wrap;
        
        li{ flex: 0 0 50%; }
    }
}

.container{
    margin: auto;
	box-sizing: border-box;
    width: 100%;
    padding: 32px 20px;
    
    @include breakpointByScreenType(large){
        padding: 32px 0;
        max-width: 1200px;
    }
}

.divider{
    margin: 32px 0;
    height: 1px;
    @include flex(align, center);
    justify-content: space-between;

    &:before, &:after{
        content: ' ';
        flex: 1 1 auto;
        border-top: $border;
    }
    span{
        flex: 0 0 auto;
        max-width: 90%;
        display: block;
        letter-spacing: 2.5px;
        color: $primary-black;
        padding: 0 8px;
        font-size: $font-S;
        font-weight: $font-weight-bold;
        line-height: $font-M;
        word-wrap: break-word;
    }
}

.avatar{
    position: relative;

    img{
        width: 72px;
        height: 72px;
        border-radius: 100%;
        z-index: 1;
        object-fit: cover;
        border: $border;
        box-sizing: border-box;
    }
}

.page-loader{
    @include position(fixed, n n 0 0);
    width: 100%;
    height: calc(100vh - 56px);
    background-color: rgba(72,72,72,0.721);
    opacity: 0.5;

    .spinner-container{
        display: inline-block;
        vertical-align: middle;
        @include position(absolute, 50% n n 50%);
        transform: translate(-50%, -50%);
    }
    .spinner{
        height: 72px;
        width: 72px;
        border: 8px solid $primary-purewhite;
        border-right-color: transparent;
        border-top-color: transparent;
        border-radius: 100%;
        animation: spin 1500ms infinite linear;
    }
}

.grecaptcha-badge{ display: none; }
.pointer{ cursor: pointer; }
.default-pointer{ cursor: default; }
.center{ text-align: center; }
.has-text-weight-normal{
    font-weight: normal;
}

.mobile-visible, .tablet-visible, .medium-visible, .large-visible{ display: none; }
.mobile-visible{ @include breakpointBySize(0, $break-mobile-max){ display: block !important; }}
.tablet-visible{ @include breakpointBySize($break-tablet-min, $break-tablet-max){ display: block !important; }}
.medium-visible{ @include breakpointBySize($break-medium-min, $break-medium-max){ display: block !important; }}
.large-visible{ @include breakpointBySize($break-large-min, 0){ display: block !important; }}
.mobile-hidden{ @include breakpointBySize(0, $break-mobile-max){ display: none; }}
.tablet-hidden{ @include breakpointBySize($break-tablet-min, $break-tablet-max){ display: none; }}
.medium-hidden{ @include breakpointBySize($break-medium-min, $break-medium-max){ display: none; }}
.large-hidden{ @include breakpointBySize($break-large-min, 0){ display: none; }}
.MuiCircularProgress-colorPrimary { color: $primary-blue!important; }