// Font Awesome
@import "~font-awesome/scss/font-awesome";
@import "~overlayscrollbars/css/OverlayScrollbars.css";
.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    max-width: 100%;
    width: fit-content;
    margin: auto;
    height: 100%;
    z-index: 9999;
    box-sizing: border-box;
    @include flex(both, center-center);
    &.hide {
        display: none;
    }
    &.account-selection {
        width: 500px;
        max-width: 100%;
    }

    padding: 0;
    @media screen and (min-width: 375px) {
        padding: 5px;
    }
    @media screen and (min-width: 414px) and (max-width: 767px) {
        padding: 10px;
    }
    .MuiDialog-container {
        width: 100%;
    }.MuiDialog-paper {
        width: 100%;
        margin: 0;
        border-radius: 1rem;
    }
    .modal {
        position: relative;
        background: $primary-purewhite;
        border-radius: 8px;
        box-shadow: $modal-shadow;
        box-sizing: border-box;
        overflow-y: auto;
        height: fit-content;
        width: 100%;
        padding: 40px;
        &.confirm-modal {
            width: 100vw;
            min-width: 100vw;
            @media screen and (min-width: 500px) {
                min-width: 100%
            }
            font-family: Arial;
            h1 {
                margin-bottom: 40px;
            }
            img {
                width: 100px;
                height: 100px;
                margin: 40px auto;
            }
            p {
                color: #484848;
                margin-bottom: 40px;
            }
            .content {
                .button {
                    font-family: Arial;
                }
            }
        }
        @include breakpointBySize(769px, 0) {
            width: 500px;
            padding: 40px;

            &.larger_modal {
                width: 550px;
            }
        }
        @include breakpointBySize(426px, 768px) {
            max-width: 512px;
            padding: 40px 60px;

            &.larger_modal {
                width: calc(100vw - 120px);
            }
        }
        @include breakpointBySize(0, 425px) {
            max-width: 410px;

            &.larger_modal {
                max-width: 420px;
            }
        }

        &.great {
            max-width: 100%;
            width: 500px;
            p.center {
                margin: 2rem 0;
            }
            .social-links {
                .button.social {
                    padding: 0 8px 0 32px!important;
                }
            }
        }

        & > .close {
            z-index: 10;
            position: absolute;
            top: 13px;
            right: 13px;
            margin: 0;
            padding: 0;

            &:before {
                font-family: "fontawesome";
                content: "\f057";
                color: $primary-lightgrey;
                transform: translateY(-50%);
                font-size: $font-L;
            }
        }

        h1 {
            font-size: 28pt;
        }
        h3 {
            font-size: 18px;
        }

        form, .pseudo-form {
            p {
                font-size: 13px;
                width: unset;
            }
            .input-group {
                & > .social,
                .input-box {
                    @include breakpointByScreenType(mobile) {
                        margin: 8px 0;

                        &:first-of-type {
                            margin-top: 0;
                        }
                        &:last-of-type {
                            margin-bottom: 0;
                        }
                    }
                    @include breakpointByScreenType(tablet) {
                        width: calc(50% - 8px);
                    }
                }
                & > .social {
                    @include breakpointByScreenType(tablet) {
                        margin-top: 0;
                        margin-bottom: 16px;

                        &:nth-last-child(-n + 2) {
                            margin-bottom: 0;
                        }
                    }
                }
                .input-box {
                    box-sizing: border-box;
                    text-align: left;

                    @include breakpointByScreenType(tablet) {
                        &.full-width {
                            flex: 0 0 100%;

                            & > p {
                                padding-top: 8px;
                                text-align: center;
                                position: initial;
                                transform: none;
                            }
                        }
                    }
                }
                label {
                    font-size: 12pt;
                }
                footer {
                    margin-top: 2.5rem;
                    p {
                        text-align: justify;
                        font-size: 9.85px;
                    }
                }
            }
            .footer {
                padding-bottom: 0;
                button {
                    width: 100%;
                    min-width: unset;
                }
                p {
                    @include breakpointByScreenType(tablet) {
                        width: 80%;
                    }
                }
            }
        }
    }
}
