// Settings
@import "../settings/variables";
@import '~jquery-ui/themes/base/core.css';
@import '~jquery-ui/themes/base/theme.css';
@import '~jquery-ui/themes/base/button.css';
@import '~jquery-ui/themes/base/menu.css';
@import '~jquery-ui/themes/base/selectmenu.css';

.container {
    ._calendar {
        background-color: #fff;
        width: 18rem;
        height: 16rem;
        box-shadow: 0 .5rem 2rem rgba(0,0,0,.2);
        .month {
            padding: 1.5rem 2rem;
            span.arrow {
                width: 1rem;
                height: 1rem;
                padding: 0;
                cursor: pointer;
                &.left  {background-image: url('../images/circle_arrow_left.svg')}
                &.right {background-image: url('../images/circle_arrow_right.svg')}
            }
            h1 {
                color: $primary-blue;
                font-size: 15px;
                text-transform: uppercase;
            }
        }
        .weekdays,
        .days {
            color: $primary-grey;
            font-size: 0.6034rem;
            font-weight: 600;
            .MuiGrid-item {
                text-align: center;
                width: 32px;
            }
        }
        .weekdays {
            padding: 0 2rem 0.5rem;
            text-transform: uppercase;
            border-bottom: 1px solid $primary-lightgrey;
            & + .days {
                margin-top: 0.5rem;
            }
        }
        .days {
            padding: 0 2rem;
            vertical-align: middle;
            &:last-of-type {
                margin-bottom: 0.5rem;
            }
        }
        .not-included {
            opacity: 0.5;
        }
        .day {
            padding: 0.5rem 0;
            &:hover {
                cursor: pointer;
            }
        }
        .selected {
            height: 100%;
            color: #eee;
            background-color: $primary-blue;
            &.start     {border-radius: 100px 0 0 100px}
            &.end       {border-radius: 0 100px 100px 0}
            &.start.end {border-radius: 100px 100px 100px 100px}
        }
    }
}