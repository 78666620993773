@import '~jquery-ui/themes/base/core.css';
@import '~jquery-ui/themes/base/theme.css';
@import '~jquery-ui/themes/base/button.css';
@import '~jquery-ui/themes/base/menu.css';
@import '~jquery-ui/themes/base/selectmenu.css';

input[type=text],
input[type=tel],
input[type=email],
input[type=password],
input[type=number],
input[type=date], 
input[type=url], 
input[type=search], 
textarea,
select{
    height: 40px;
    box-sizing: border-box;
    padding: 12px;
    border-radius: 4px;
    border: $border;
    font-family: $font-family;
    font-size: $font-S;
    color: $primary-black;
    letter-spacing: $font-letter-spacing;
    outline: 0;
    background-color: transparent;

    &:active, &:focus{
        border-color: $primary-blue;
        outline: 0;
    }
}
input:not([value=""]),
select:not([value=""]) {
    background-color: $primary-white;
}
input[type=date] {
    background-color: transparent;
}
input[type=date]:before {
    color       : #aaa;
    width       : 100%;
}
input[type=date]:focus:before,
input[type=date]:valid:before {
    content: ""!important;
    width  : 0;
}

input[type=date]::-webkit-calendar-picker-indicator{
    opacity: 0;
    width: 30px;
    margin-right: -33px;
    margin-left: 0;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
::placeholder {
    letter-spacing: $font-letter-spacing;
    font-family: $font-family;
    color: $primary-lightgrey;
    opacity: 1;
    font-size: $font-S;
}
select{
    padding: 0 12px;
    appearance: none;
    background-image:
        linear-gradient(60deg, transparent 50%, $primary-lightgrey 50%),
        linear-gradient(120deg, $primary-lightgrey 50%, transparent 50%);
    background-position:
        calc(100% - 18px) 16px,
        calc(100% - 14px) 16px;
    background-size:
        6px 6px,
        6px 6px;
    background-repeat: no-repeat;

    &:active, &:focus{
        background-image:
            linear-gradient(60deg, transparent 50%, $primary-blue 50%),
            linear-gradient(120deg, $primary-blue 50%, transparent 50%);
    }
}
input:read-only, input:disabled, select:disabled{
    color: $primary-lightgrey;
    background: $primary-white;
}
// select option:disabled{ display: none; }
textarea{
    resize: none;
    height: 100px;
}
input[type=checkbox] {
    position: absolute;
    opacity: 0;
    & + label {
        cursor: pointer;
        position: relative;
        padding: 0;
        @include flex(justify, space-between);
        align-items: center;
        color: $primary-grey;
        font-weight: 400 !important;
        font-size: $font-XS;
        line-height: $font-M;
        padding-left: 24px;
        margin-bottom: 0 !important;
    }
    & + label:before {
        content: '';
        width: 12px;
        height: 12px;
        border: 1px solid $primary-lightgrey;
        background: $primary-purewhite;
        border-radius: 2px;
        position: absolute;
        box-shadow: $input-shadow;
        left: 0;
        top: calc(50% - 7px);
    }
    &:disabled + label { cursor: auto; }
    &:disabled + label:before {
        background-color: $primary-purewhite;
        box-shadow: none;
    }
    &:focus + label:before{ border: 1px solid $primary-blue; }
    &:checked + label:before {
        background: url('../images/check.svg') no-repeat;
        background-size: 10px 10px;
        background-position: center;
        background-color: $primary-blue;
    }
    &[readonly]:checked + label:before {
        background-color: $primary-lightgrey;
    }
}
.valid{
    .input-prepend {
        &.file {
            label {
                background-image: none;
            }
        }
    }
}
.disabled {
    pointer-events:none!important;
}
.input-prepend{
    position: relative;
        
    input, select, textarea, .ui-selectmenu-button.ui-button{
        display: block;
        width: 100%;
        margin-bottom: 4px;
    }
    &>.toggle, &>.link{
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
        font-size: $font-S;
        color: $primary-blue;
        cursor: pointer;
    }
    .spinner-container{
        @include position(absolute, 50% 10px n n);
        transform: translatey(-50%);
        display: none;

        .spinner{
            height: 12px;
            width: 12px;
            border: 4px solid $primary-blue;
            border-right-color: transparent;
            border-top-color: transparent;
            border-radius: 100%;
            animation: spin 1500ms infinite linear;
        }
    }
    &.file{
        cursor: pointer;
        &.readonly {
            cursor: default
        }
        input{
            height: 0;
            margin: 0;
            opacity: 0;

            &:active+label, &:focus+label{ border-color: $primary-blue; }
            &:disabled+label{ 
                background-color: $primary-white; 
                cursor: default;
            }
        }
        &.filled label{ 
            background-image: none; 
            position: relative;

            img{ 
                display: block;
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
            }
            small{
                display: none;
            }
        };
        &.pdf label img, &.docx label img, &.doc label{ 
            img, small{
                display: none;
            }
        }
        &.pdf label{ background-image: url('../images/pdf.svg'); };
        &.docx label, &.doc label{ background-image: url('../images/docx.svg'); };
        &.with-instruction {
            label {
                background-position: center 8px;
                background-size: 64px;
            }
        }
        label{
            width: 100%;
            height: 100px;
            box-sizing: border-box;
            border-radius: 4px;
            border: $border;
            padding: 0;
            margin-bottom: 4px;
            cursor: pointer;
            background-image: url('../images/upload.svg');
            background-position: center;
            background-repeat: no-repeat;
            background-size: 75px;

            &:active{ border-color: $primary-blue; }
            img{
                width: 100%;
                height: 100%;
                object-fit: contain;
                background: none;
                border: none;
                outline: none;
                display: none;
            }
            small{
                position: absolute;
                bottom: 8px;
                text-align: center;
                width: 100%;
                color: $primary-lightgrey;
            }
        }
    }
    &.mobile{
        @include flex(both, center-center);

        select, .ui-selectmenu-button.ui-button{
            flex: 0 0 100px;
            border-radius: 4px 0 0 4px;
        }
        input{
            border-radius: 0 4px 4px 0;
            border-left-color: transparent !important;

            &:active, &:focus{ border-left-color: $primary-blue; }
        }
    }
    &.social{
        &:after{
            position: absolute;
            top: 14px;
            left: 40px;
            font-size: $font-S;
            color: $primary-black;
        }
        input:read-only{ color: $primary-black; }
        &.icon-facebook{
            input{ padding-left: 143px; }
            &:after{ content: 'facebook.com/' }
        }
        &.icon-twitter{
            input{ padding-left: 90px; }
            &:after{ content: 'x.com/' }
        }
        &.icon-instagram{
            input{ padding-left: 149px; }
            &:after{ content: 'instagram.com/' }
        }
        &.icon-linkedin{
            input{ padding-left: 135px; }
            &:after{ content: 'linkedin.com/' }
        }
        &.icon-link{
            input{ padding-left: 84px; }
            &:after{ content: 'http://' }
        }
        &.icon-jpt{
            input{ padding-left: 114px; }
            &:after{ content: 'justpay.to/'; }
        }
    }
    .select-field {
        color: $primary-black;
        caret-color: transparent;
    }
}

.ui-state-hidden{
    display: none;
}
.ui-selectmenu-button{
    font-family: $font-family;
    font-size: $font-S;
    letter-spacing: $font-letter-spacing;
    color: $primary-black;

    &.ui-button{
        width: 100%;
        height: 40px;
        box-sizing: border-box;
        padding: 10px 12px;
        border-radius: 4px;
        border: $border;
        margin-right: 0;
        background-color: unset;
        background-image:
            linear-gradient(60deg, transparent 50%, $primary-lightgrey 50%),
            linear-gradient(120deg, $primary-lightgrey 50%, transparent 50%);
        background-position:
            calc(100% - 14px) 16px,
            calc(100% - 10px) 16px;
        background-size:
            6px 6px,
            6px 6px;
        background-repeat: no-repeat;

        &:active, &:focus{
            border-color: $primary-blue;
            outline: 0;
            background-image:
                linear-gradient(60deg, transparent 50%, $primary-blue 50%),
                linear-gradient(120deg, $primary-blue 50%, transparent 50%);
        }
        &.ui-state-disabled{
            opacity: unset;
            color: $primary-lightgrey;
            background: $primary-white;
        }
    }
    .ui-icon-triangle-1-s{
        bottom: 0%;
        left: 1%;
        &.flag {
            bottom: 100%;
        }
    }
    .ui-icon{
        object-fit: contain;
        margin-right: 8px;
    }
}
.ui-selectmenu-text{
    margin-right: 16px;
}
.ui-menu{
    border-radius: 4px;
    border: $border;

    .ui-menu-item-wrapper{
        font-size: $font-S;
        color: $primary-black;
        font-family: $font-family;
        letter-spacing: $font-letter-spacing;
        padding: 10px 12px;

        &.ui-state-active{
            border: 0;
            background: $primary-blue;
            color: $primary-purewhite;
        }
    }
    &-icons .ui-menu-item-wrapper{
        padding: 10px 12px 10px 40px;
    }
    .ui-icon{
        object-fit: contain;
        background-image: none;
        left: 12px;
    }
}
.ui-front{
    z-index: 99999;
}

.input-set {
    & + & {
        margin-top: 16px;
    }
    & > .base-input {
        &:first-child:not(:only-child) .base-input__control {
            border-radius: 4px 4px 0 0 !important;
            border-bottom: none;
        }
        &:not(:first-child):not(:last-child) .base-input__control {
            border-radius: 0 !important;
            border-bottom: none;
        }
        &:last-child:not(:only-child) .base-input__control {
            border-radius: 0 0 4px 4px !important;
        }
    }
    & > .input-row {
        &:first-child:not(:only-child) {
            & > .base-input {
                &:first-child:not(:only-child) .base-input__control {
                    border-radius: 4px 0 0 0 !important;
                }
                &:last-child:not(:only-child) .base-input__control {
                    border-radius: 0 4px 0 0 !important;
                }
            }
        }
        &:not(:first-child):not(:last-child)
            > .base-input
            .base-input__control {
            border-radius: 0 !important;
        }
        &:last-child:not(:only-child) {
            & > .base-input {
                &:first-child:not(:only-child) .base-input__control {
                    border-radius: 0 0 0 4px !important;
                }
                &:last-child:not(:only-child) .base-input__control {
                    border-radius: 0 0 4px 0 !important;
                }
            }
        }
    }
}
.input-row {
    display: flex;

    .base-input {
        flex-basis: 0;
        flex-grow: 1;
        flex-shrink: 1;

        &:first-child:not(:only-child) .base-input__control {
            border-radius: 4px 0 0 4px !important;
            border-right: none;
        }
        &:not(:first-child):not(:last-child) .base-input__control {
            border-radius: 0 !important;
            border-right: none;
        }
        &:last-child:not(:only-child) .base-input__control {
            border-radius: 0 4px 4px 0 !important;
        }
        &.is-three-quarters {
            flex: none;
            width: 75%;
        }
        &.is-two-thirds {
            flex: none;
            width: 66.6666%;
        }
        &.is-half {
            flex: none;
            width: 50%;
        }
        &.is-one-third {
            flex: none;
            width: 33.3333%;
        }
        &.is-one-quarter {
            flex: none;
            width: 25%;
        }
    }
}
.base-input {
    position: relative;

    &__icon {
        position: absolute;
        width: 16px;
        height: 16px;
        top: 8px;
        left: 8px;
        object-fit: contain;
    }
    &__control {
        width: 100%;
        height: 32px;
        padding: 8px;
        border: solid 1px $primary-lightgrey;
        background: 0;
        font-size: $font-S;
        color: $primary-black;
        border-radius: 4px;

        &.has-icon {
            padding-left: 32px !important;
        }
        &:active,
        &:focus {
            border: solid 1px $primary-blue !important;
            outline: 0;
        }
        &:disabled {
            background-color: $primary-offwhite;
        }
    }
}

.signature-input {
    width: 100%;
    max-width: 420px;
    max-height: 420px;
    margin: 0 auto 1rem;
    canvas {
        border: 1px solid #CCC;
        border-radius: 4px;
        background-color: #eeeeee;
    }
    &__undo-button {
        background: 0;
        background-color: #ffffff;
        border-radius: 4px;
        border: $primary-blue 1px solid;
        position: absolute;
        top: 8px;
        right: 8px;
        position: absolute;
        width: 32px;
        height: 32px;
        margin: 8px!important;
        &:before {
            margin-left: -45%;
            background-size: contain;
            font-family: fa-solid;
            font-size: $font-XS;
            line-height: 12px;
            text-align: center;
            width: 12px;
            height: 12px;
            content: '\f0e2';
            color: $primary-blue;
        }
    }
}
.caret {
    position: absolute;
    right: 10px;
    top: 30%;
    &.open {
        background-position: 0 -16px;
    }
    color: $primary-lightgrey;
}
.textarea-checkbox {
    height: fit-content!important;
    padding: 0.5rem 1rem;
    margin-bottom: -20px;
    input[type=checkbox] {
        cursor: pointer;
        display: block;
        width: 100%;
        z-index: 2;
        & + label {
            cursor: pointer;
        }
    }
}
input[type=radio] {
    display: inline-block;
    width: auto;
    margin: 3px 5px;
    & + label {
        display: inline-block;
    }
}