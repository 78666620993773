/* open-sans-regular - latin */
@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 400;
	src: url('../fonts/open-sans-v17-latin-regular.eot'); /* IE9 Compat Modes */
	src: local('Open Sans Regular'), local('OpenSans-Regular'),
		 url('../fonts/open-sans-v17-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('../fonts/open-sans-v17-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
		 url('../fonts/open-sans-v17-latin-regular.woff2') format('woff2'), /* Modern Browsers */
		 url('../fonts/open-sans-v17-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('../fonts/open-sans-v17-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
	font-display: swap;
  }

@font-face {
	font-family: 'fa-regular';
	font-style: normal;
	font-weight: 400;
	src:
        url('~@fortawesome/fontawesome-free/webfonts/fa-regular-400.woff2') format('woff2'),
        url('~@fortawesome/fontawesome-free/webfonts/fa-regular-400.woff2') format('woff2'),
        url('~@fortawesome/fontawesome-free/webfonts/fa-regular-400.ttf') format('truetype');
	font-display: swap;
}

@font-face {
	font-family: 'fa-brand';
	font-style: normal;
	font-weight: normal;
	src:
        url('~@fortawesome/fontawesome-free/webfonts/fa-brands-400.woff2') format('woff2'),
        url('~@fortawesome/fontawesome-free/webfonts/fa-brands-400.woff2') format('woff2'),
        url('~@fortawesome/fontawesome-free/webfonts/fa-brands-400.ttf') format('truetype');
	font-display: swap;
}

@font-face {
	font-family: 'fa-solid';
	font-style: normal;
	font-weight: 900;
	src: 
        url('~@fortawesome/fontawesome-free/webfonts/fa-solid-900.woff2') format('woff2'),
        url('~@fortawesome/fontawesome-free/webfonts/fa-solid-900.woff2') format('woff2'),
        url('~@fortawesome/fontawesome-free/webfonts/fa-solid-900.ttf') format('truetype');
	font-display: swap;
}

.is-capitalized {
    text-transform: capitalize !important;
}
.is-lowercase {
    text-transform: lowercase !important;
}
.is-uppercase {
    text-transform: uppercase !important;
}
.is-italic {
    font-style: italic !important;
}
