button{
    font-family: $font-family;
    letter-spacing: 2px;
    margin-top: 0;
    margin-bottom: 16px;
    color: $primary-blue;
    border-radius: 4px;
    padding: 2px 12px;
    font-size: 17px;
    background: 0;
    border: 0;
    cursor: pointer;

    &.filled, &.bordered, &.text{
        position: relative;
        width: 100%;
        min-height: 40px;
        background: $primary-lightergrey;

        &>section{ position: relative; }
        &:hover, &:active{ filter: brightness(85%); }
        &:disabled{
            opacity: 0.5;

            &:hover, &:active{ filter: none; }
        }
        &.round{ border-radius: 20px; }
        &.half{ max-width: 50%; }
    }
    &.filled{
        color: $primary-black !important;
        
        &.blue  { 
            background: #0099DD;
            color: $primary-purewhite !important;
        }
        &.green { 
            background: #36a749;
            color: $primary-purewhite !important;
        }
        &.red   { 
            background: #e32b2b;
            color: $primary-purewhite !important;
        }
        &.yellow{ 
            background: #eca01f;
            color: $primary-purewhite !important;
        }
        &.social{    
            color: $primary-purewhite !important;
            font-size: $font-S;
            text-align: left;
            padding-left: 36px;
            
            &:before{ color: $primary-purewhite; }
            &.icon-facebook{ 
                background: $social-fb; 

                &:before{ content: '\f39e' !important; }
            }
            &.icon-twitter{ background: $social-twitter; }
            &.icon-instagram{ 
                background: $social-instagram; 

                &:before{
                    background: none;
                    font-family: fa-brand;
                    content: '\f16d';
                }
            }
            &.icon-linkedin{ background: $social-linkedin; }
            &.icon-link{ background: $primary-grey; }
            &.icon-email{ 
                background: $primary-black;

                &:before{ 
                    background: url('../images/email-white.svg');
                    background-size: contain;
                }
            }
        }
    }
    &.bordered{
        background: $primary-purewhite;
        
        &:hover{ background: $primary-white; }
        &.grey{
            border: $primary-lightgrey 1px solid;
            color: $primary-grey;
        }
        &.blue{
            border: $primary-blue 1px solid;
            color: $primary-blue;
        }
        &.green{
            border: $secondary-green 1px solid;
            color: $secondary-green;
        }
        &.red{
            border: $secondary-red 1px solid;
            color: $secondary-red;
        }
        &.yellow{
            border: $secondary-yellow 1px solid;
            color: $secondary-yellow;
        }
        &.round:hover{
            background: $primary-blue;
            border-color: $primary-blue;
            color: $primary-purewhite;
            filter: brightness(100%);
        }
    }
    &.text{
        background: $primary-purewhite;

        &:hover{ background: $primary-white; }
        &.blue{ color: $primary-blue; }
        &.green{ color: $secondary-green; }
        &.red{ color: $secondary-red; }
        &.yellow{ color: $secondary-yellow; }
    }
    &.loading{
        opacity: 0.75;
        cursor: progress;
        .spinner-container{ display: inline-block; }
    }
    .spinner-container{
        display: none;
        vertical-align: middle;
        margin-right: 4px;
    }
    .spinner{
        height: 12px;
        width: 12px;
        border: 4px solid $primary-purewhite;
        border-right-color: transparent;
        border-top-color: transparent;
        border-radius: 100%;
        animation: spin 1500ms infinite linear;
    }
    &.icon {
        padding: 0 8px;
        width: auto;
    }
}