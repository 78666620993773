@import "./settings/responsive";
@import "./modules/header";

.login {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    &.action-box.content-box {
        padding: 40px 1.5rem;
        @media(min-width: 780px) {
            padding: 40px 3rem;
        }
    }
    .input-box {
        .input-prepend {
            input {
                font-family: Arial;
            }
            .forgot-password {
                font-size: 12px;
                color: #009CDE;
                a {
                    font-weight: normal;
                }
            }
        }
    }
    .input-group {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .input-box {
            margin: 0 0 16px;
            min-width: 50%;
        }
    }
    .logo {
        width: 60px;
        height: 60px;
        margin: 1rem auto 3rem;
    }
    .button {
        font-family: Arial, Helvetica, sans-serif;
    }
    .divider { 
        font-size      : 14px;
        text-transform : capitalize;
        margin: 1rem auto 2rem;
        span {
            font-weight   : normal;
            flex          : 0 0 auto;
            max-width     : 90%;
            display       : block;
            letter-spacing: 0.15rem;
            padding       : 0 0.5rem;
        }
    }
    .pseudo-form .error-container {
        margin: 0 0 2rem;
    }
    .input-group {
        margin: 0;
        .input-box {
            height: 18px;
            margin-bottom: 20px;
            .input-prepend {
                height: fit-content;
                label {
                    color: #8E8E91;
                }
            }
        }
    }
}
