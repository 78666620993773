// Settings
@import "./settings/variables";
@import "./settings/mixins";
@import "./settings/responsive";
@import "./settings/animation";

.invites {
    h3,
    .partners {
        margin-bottom: 40px;
    }
    .divider {
        margin: 40px 0;
    }
    .partners {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        height: 32px;
        overflow: hidden;

        a {
            margin: 0 8px 0;
        }
        .avatar,
        .avatar:after,
        img {
            overflow: hidden;
            width: 32px;
            height: 32px;
        }
    }
    .biller_link {
        margin-top: 40px;
        text-align: center;

        a {
            font-size: 14px;
        }
    }
}
