form, .pseudo-form{
    box-sizing: border-box;
    width: 100%;
    margin: auto;
    text-align: center;
    
    @include breakpointByScreenType(tablet){ max-width: 750px; }
    .header{
        p {
            width: 80%;
            margin: auto;
            margin-bottom: 2rem;
        }
        h2 {
            font-weight: bold;
        }
    }
    .input-group{
        text-align: left;
        margin: 0;

        @include breakpointByScreenType(tablet){
            @include flex(justify, space-between);
            flex-wrap: wrap;
        }
        &>a{ width: calc(50% - 16px);}
        &>.social{
            @include breakpointByScreenType(mobile){
                margin: 16px 0;

                &:first-of-type{ margin-top: 0; }
                &:last-of-type{ margin-bottom: 0; }
            }
            @include breakpointByScreenType(tablet){
                width: calc(50% - 16px);
                margin-top: 0;
                margin-bottom: 16px;
                
                &:nth-last-child(-n+2){ margin-bottom: 0; }
            }
        }
        label~.social{ margin-bottom: 0; }    
        .input-box{
            box-sizing: border-box; 
            text-align: left;

            @include breakpointByScreenType(mobile){
                margin: 16px 0;

                &:first-of-type{ margin-top: 0; }
                &:last-of-type{ margin-bottom: 0; }
            }
            @include breakpointByScreenType(tablet){ 
                width: calc(50% - 16px); 

                &.full-width{ 
                    flex: 0 0 100%; 
    
                    &>p{
                        padding-top: 8px; 
                        text-align: center;
                        position: initial;
                        transform: none;
                    }
                }
            }
        }
    }
    .input-box{
        box-sizing: border-box; 
        text-align: left;
        margin-bottom: 30px;
        &.error {
            scroll-margin-top: 75px;
            margin-bottom: 18px;
        }
        small:empty {
            min-height: 0;
        }
        @include breakpointByScreenType(tablet){ position: relative; }
        &.full-width:nth-last-child(2){ margin-bottom: 16px; }
        label{ 
            position: relative;
            margin-bottom: 12px;
            line-height: $font-M;
            min-height: $font-M;

            a {
                letter-spacing: normal;
                float: right;
                margin-right: 0.5rem;
                font-weight: normal;
            }
            div {
                display: block;
                font-size: 14px;
            }
            .label {
                display: inline;
            }
            span.right{ 
                float: right;
                margin-left: auto;
                margin-right: 0;
                margin-bottom: 5px;
            }
            .error{
                color: $secondary-red;
                // font-weight: 600;
                animation: shake 0.5s linear;
            }
            .fa:before{ font-size: 12px; }
            section{
                box-sizing: border-box;
                min-height: 40px;
                font-family: $font-family;
                position: absolute;
                border-radius: 4px;
                z-index: 9;
                top: calc(100% + 12px);
                background-color: $primary-purewhite;
                border: $border;
                box-shadow: $box-shadow;
                display: none;
                width: 100%;
                border-radius: 2px;
                overflow: hidden;

                &.small{ width: 50%; }
                .body{
                    padding: 12px;
                    display: block;
                }
                img{ width: 100%; }
                p, h5{ line-height: $font-M; }
                h5{ 
                    margin-bottom: 8px; 

                    &:only-child{ margin-bottom: 0; }
                }
            }
            &.tooltip:hover section{ 
                display: block;
                
                p{
                    margin-bottom: 4px;

                    &:last-child{
                        margin-bottom: 0;
                    }
                }
                img{
                    padding: 0.5rem;
                    text-align: center;
                }
            }
        }
        input[type=radio] + label {
            font-weight: normal;
        }
        input{
            ~.focus-tooltip{ 
                box-sizing: border-box;
                min-height: 40px;
                font-family: $font-family;
                position: absolute;
                border-radius: 4px;
                z-index: 8;
                top: 100%;
                background-color: $primary-purewhite;
                border: $border;
                box-shadow: $box-shadow;
                display: none;
                width: 100%;
                border-radius: 2px;
                overflow: hidden;

                &.small{ width: 50%; }
                .body{ padding: 12px; }
                img{ width: 100%; }
                p, h5{ line-height: $font-M; }
                h5{ 
                    margin-bottom: 8px; 

                    &:only-child{ margin-bottom: 0; }
                }
                &:hover{
                    display: block;
                    p{
                        margin-bottom: 4px;
    
                        &:last-child{
                            margin-bottom: 0;
                        }
                    }
                }
            }
            ~.absolute-container {
                position: absolute;
                top: 100%;
                min-width: 100%;
                width: auto;
                display: none;
                overflow: hidden;
                z-index: 8;
                margin-bottom: 1rem;
                box-shadow: $box-shadow;
                &.right {
                    width: auto;
                    height: auto;
                    top: 0;
                    left: calc(100% + 0.5rem);
                    min-width: 0;
                }
                .selection{ 
                    box-sizing: border-box;
                    min-height: 40px;
                    font-family: $font-family;
                    border-radius: 4px;
                    background-color: $primary-purewhite;
                    border: $border;
                    width: 100%;
                    border-radius: 2px;
                    &.icons {
                        width: 120px;
                        padding: 0.6rem;
                        img {
                            min-width: 25px;
                            min-height: 25px;
                        }
                        img:hover {
                            cursor: pointer;
                            background-color: $primary-lightergrey;
                        }
                    }
                    .dropdown {
                        &.body {
                            padding: 0.25rem 0;
                            overflow-y: hidden;
                        }
                        &-item {
                            display: block;
                            padding: 0.25rem 1.5rem;
                            clear: both;
                            font-weight: 400;
                            color: #212529;
                            text-align: inherit;
                            white-space: nowrap;
                            background-color: transparent;
                            border: 0;
                            overflow-y: hidden;
                            &.pointer {
                                &:hover, &:focus {
                                    background-color: #eee;
                                }
                            }
                            .accordion-panel {
                                max-height: 0;
                                overflow: hidden;
                                &.active {
                                    max-height: 100vh;
                                    transition: max-height 0.75s ease-in;
                                    margin: 0.5rem 0;
                                }
                            }
                            .accordion {
                                cursor: pointer;
                                transition: 0.4s;
                                display: block;
                                .caret {
                                    position: relative;
                                    top: auto;
                                    bottom: 0;
                                    right: 0;
                                }
                            }
                            
                        }
                    }
                    .search-bar {
                        padding: 0.5rem;
                        .icon-search {
                            margin: 0;
                            padding: 0;
                        }
                    }
                }
                &.open {
                    display: block;
                    
                    p{
                        margin-bottom: 4px;
    
                        &:last-child{
                            margin-bottom: 0;
                        }
                    }
                }
            }
            &:focus~.focus-tooltip, ~.focus-tooltip:hover {
                display: block;
                
                p{
                    margin-bottom: 4px;

                    &:last-child{
                        margin-bottom: 0;
                    }
                }
            }
        }
        small{
            letter-spacing: $font-letter-spacing;
            color: $primary-grey;
            display: block;
            font-size: $font-XS;
            line-height: $font-XS;
            min-height: $font-XS;
            margin-top: 4px;
            word-wrap: break-word;
        }
        &.loading .spinner-container{ display: block; }
        span.toggle{ transition: right 0.25s ease-out; }
        div.icon{
            width: 16px;
            height: 16px;
            position: absolute;
            top: 50%;
            right: 12px;
            transform: translateY(-50%);
            opacity: 0;
            transition: opacity 0.25s ease-out;

            &:before{ left: 0; }
            &.valid:before{ color: $secondary-green; }
            &.error:before{ color: $secondary-red; }
        }
        .dropdown {
            div.icon {
                margin-right: 1rem!important;
            }
        }
        textarea, input[type=file]{
            &~.spinner-container, &~div.icon{
                top: unset;
                transform: none;
            }
            &~.spinner-container{
                bottom: 8px;
                right: 8px;
            } 
            &~div.icon{
                bottom: 10px;
                right: 10px;
            } 
        }
        select~div.icon{ right: 32px; }
        input~div.icon{ right: 12px; }
        &.valid{
            input, select, textarea, input[type=checkbox]+label:before, input[type=file]+label, .ui-selectmenu-button.ui-button, .preview{
                border-color: $secondary-green;
            }
            input, select, input.ui-selectmenu-button.ui-button{
                padding-right: 40px;
            }
            .input-prepend {
                div.valid{ opacity: 1; }
                span.toggle, span.link{ right: 40px; }
            }
        }
        &.error{
            input, select, textarea, input[type=checkbox]+label:before, input[type=file]+label, .ui-selectmenu-button.ui-button, .preview{
                border-color: $secondary-red;
            }
            input, select, input.ui-selectmenu-button.ui-button{
                padding-right: 40px;
            }
            > small{
                color: $secondary-red;
                animation: shake 0.5s linear;
            }
            .input-prepend {
                div.error{ opacity: 1; }
                span.toggle, span.link{ right: 40px; }
            }
        }
        & > p{ 
            width: 100%; 
            line-height: $font-M;

            @include breakpointByScreenType(tablet){
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        &:nth-child(even) > a{
            float: right;
            line-height: 18px;
        }
    }
    .error-container{
        margin: 8px;
        height: fit-content;
        font-size: $font-XS;
        color: $secondary-red;
        transition: height 0.5s ease, opacity 0.25s ease 0.25s;

        &.active{
            opacity: 1;
        }
        small{
            display: none !important;
            color: $secondary-red;
            font-size: $font-XS;

            &:first-child{
                display: block !important;
                animation: shake 0.5s linear;
            }
        }
    }
    .remember-me{ 
        @include flex(justify, space-between);

        .input-box{ 
            width: 50%; 
            margin: 0;
        }
        a{ 
            margin-top: 0; 
            text-align: right;
        }
        label, a{
            @include breakpointByScreenType(mobile){ font-size: 12px !important; }
            @include breakpointByScreenType(tablet){ font-size: 14px !important; }
        }
    }
    .forgot-password{
        margin: 16px 0; 
        margin-top: 0; 
        text-align: right;
        
        p
        h6{
            width: 100%;
            float: right;
            margin-top: 0; 
            text-align: right;
            @include breakpointByScreenType(mobile){ font-size: 12px !important; }
            @include breakpointByScreenType(tablet){ font-size: 14px !important; }
        }
    }
    .footer{
        text-align: center;
        padding-bottom: 16px;
        
        .buttons{
            @include breakpointByScreenType(tablet){ @include flex(justify, space-between);  }
        }
        & button, & a>button{
            margin-top: 0;

            @include breakpointByScreenType(mobile){
                margin: 8px 0;

                &:first-of-type{ margin-top: 0; }
                &:last-of-type{ margin-bottom: 0; }
            }
            @include breakpointByScreenType(tablet){ width: calc(50% - 16px); }
            @include breakpointByScreenType(medium){ min-width: 280px; }
        }
        p{ 
            margin: 0 auto;

            @include breakpointByScreenType(tablet){ width: calc(50% - 16px) !important; }
            &:first-child{ margin-top: 0; }
        }
    }
    label.preview.show {
        background-image: none;
        img {
            display: block;
        }
        small {
            display: none;
        }
    }
    small{
        letter-spacing: $font-letter-spacing;
        color: $primary-grey;
        display: block;
        margin-top: 16px;
        font-size: $font-S;
        line-height: $font-S;
        min-height: $font-S;
        word-wrap:normal;
    }
    &.bank-verification {
        small{
            &.instructions {
                margin-bottom: 2rem
            }
        }
    }
}