// Settings
@import "./settings/variables";
@import "./settings/mixins";
@import "./settings/responsive";
@import "./settings/animation";

@import "~pageable/src/pageable.scss";

.disabled-onepage-scroll, .disabled-onepage-scroll .wrapper {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.pg-wrapper.pg-vertical {
    padding: 0!important
}

.MuiBackdrop-root {
    background-color: rgba(0,0,0,.7)!important;
}

main {
    h1 {
        font-size: 48px;
        line-height: 72px;
    }
    a {
        text-decoration: underline;
        cursor: pointer;

        &.black { color: $primary-black; }
    }
    p {
        margin-bottom: 24px;

        &:last-of-type:not(:last-child) { margin-bottom: 40px; }
        &:last-child, &:only-child { margin-bottom: 0; }
    }

    .section {
        @include flex();
        @include breakpointBySize(0, $break-medium-min) {
            flex-direction: column;
            height: unset !important;
        }

        &__pane {
            box-sizing: border-box;

            @include breakpointBySize(1025px) {
                width: 50%;
                height: 100vh;
            }
        }
    }
    .content {
        background-color: $primary-purewhite;

        @include breakpointBySize(1025px) {
            @include flex(justify, center);

            flex-direction: column;
        }

        &__wrapper {
            @include breakpointBySize(0, $break-medium-min) { padding: 40px; }
            @include breakpointBySize(1025px) { padding: 0 40px; }
            @include breakpointByScreenType('large') { padding: 0 128px; }
        }
    }
    .graphic {
        align-items: center;
        background-color: $primary-offwhite;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        @include flex(justify, center);
        @include breakpointByScreenType('mobile') {
            height: 100vw;
            padding: 40px;
        }
        @include breakpointBySize($break-tablet-min, $break-medium-min) {
            height: 65vw;
            padding: 72px;
        }
        @include breakpointBySize(1025px, 0) { padding: 112px; }

        img {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
        }
    }
    .intro {
        &__logo {
            margin-bottom: 48px;

            @include breakpointByScreenType('mobile') { width: 100%; }
            @include breakpointByScreenType('tablet') { height: 36px; }
        }
        .graphic {
            background-color: #4481EB;
            background-image: linear-gradient(to top right, #4481EB, #04BEFE);
        }
    }
    .about {
        .graphic { background-image: radial-gradient(circle farthest-side, $primary-purewhite, #E8E8E8); }
    }
    .partners {
        &__icons {
            display: grid;
            flex-wrap: wrap;
            justify-content: space-between;
            grid-template-columns: repeat(auto-fill, 64px);
            gap: 8px;
            height: 136px;
            overflow: hidden;

            .avatar, .avatar:after, img {
                overflow: hidden;
                width: 64px;
                height: 64px;
            }

            @include breakpointBySize(1025px) {
                height: 88px;
                grid-template-columns: repeat(auto-fill, 40px);

                .avatar, .avatar:after, img {
                    width: 40px;
                    height: 40px;
                }
            }
        }
        .graphic { background-image: url('./images/landing-page/justpayto-philippines-billspayment.jpg'); }
    }
    .business {
        .graphic { background-image: url('./images/landing-page/justpayto-philippines-paymentsystem.jpg'); }
    }
    .security {
        &__certifications {
            @include flex(align, center);

            img {
                height: 36px;
                margin-right: 16px;
            }
        }
        .graphic { background-image: url('./images/landing-page/justpayto-philippines-securepayments.jpg'); }
    }
    .contact {
        &__social {
            @include flex(align, center);

            a {
                margin-right: 12px; 
                text-decoration: none;
            }
            &__item {
                width: 28px; 
                height: 28px;
                box-sizing: border-box;
                border-radius: 100%;
                background-color: $primary-lightgrey;
                padding: 6px;

                &:hover { background-color: $primary-blue; }
                &:before {
                    content: '';
                    font-family: fa-brand;
                    color: $primary-purewhite;
                    font-size: 16px;
                    line-height: 16px;
                    text-align: center;
                    width: 16px;
                    height: 16px;
                    display: block;
                }
                &.facebook:before { content: '\f39e'; }
                &.twitter:before { content: '\e61b'; }
                &.instagram:before { content: '\f16d'; }
                &.linkedin:before { content: '\f0e1'; }
            }
        }
        .graphic { background-image: url('./images/landing-page/justpayto-philippines-contact.jpg'); }
    }
}

.modal-container {
    .modal {
        &.login .input-box {
            @include breakpointBySize(0, 375px) {
                input {
                    font-size: 11px;

                    &::placeholder {
                        font-size: 11px;
                    }   
                }
                .link {
                    font-size: 11px;
                }
            }
        }
        &.login, &.early-access, &.twofa {
            .logo {
                margin-bottom: 40px;
            }
            .input-box {
                margin-top: 20px;
                margin-bottom: 0;

                input {
                    margin-bottom: 0;
                }
                &:last-of-type {
                    margin-bottom: 0;
                }
            }
            .error-container {
                display: block !important;
                height: fit-content;
                margin: 14px 0;
            }
            .footer {
                margin-top: 0 !important;
            }
            .divider {
                margin: 30px 0;
            }
        }
        &.early-access {
            h3, .partners {
                margin-bottom: 40px;
            }
            .biller_link {
                margin-top: 40px;
            }
        }
        h3 { text-align: center; }
        p {
            @include breakpointByScreenType(mobile){ font-size: 12px; }
            @include breakpointByScreenType(tablet){ font-size: 14px; }
        }
        .button {
            &.social {
                span {
                    font-size: 14px;
                }
            }
        }
        .divider ~ a > .button {
            margin-top: 0;
        }
        .partners {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            height: 32px;
            overflow: hidden;

            a {
                margin: 0 8px 0;
            }
            .avatar, .avatar:after, img {
                overflow: hidden;
                width: 32px;
                height: 32px;
            }
        }
        .biller_link {
            text-align: center;
            margin-top: 16px;

            a {
                font-size: 14px;
            }
        }
        &.information {
            width: 580px;
            height: 580px;
            max-width: unset;
            padding: 40px;
            max-width: 100%;

            h3 {
                position: absolute;
                top: 8px;
                right: 0;
                left: 0;
            }
            .content, iframe {
                width: 100%;
                height: 100%;
            }
            .close.is-texted {
                top: unset;
                right: 0;
                left: 0;
                bottom: 8px;
                width: fit-content;
                margin: auto;

                &:before {
                    content: '';
                }
            }
        }
    }
}
