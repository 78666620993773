.achievement{
    margin: auto;
    box-sizing: border-box;
    border-radius: 4px;
    text-align: center;
    margin: 40px 0;

    &.wrapped{
        border: $border;
        box-shadow: $inset-shadow;
        background: $primary-white;
        padding: 16px;
        min-height: 88px;
        margin-top: 12px;
        width: 100%;
    }
    &.attention{
        border-color: #dad7b5;
        background: #f6feca;
    }
    &.hide{ display: none; }
    
    .content{
        @include flex(justify, space-between);
        flex-direction: row;
        align-items: center;
        text-align: left;
        padding: 0 !important;
        background-color: inherit;

        &>img, &>div{
            flex-basis: 48px;
            width: 48px;
            height: 48px;
        }
        &>section{
            flex-basis: calc(100% - 64px);
            width: 100%;
        }
    }
    .action{ margin: 16px auto auto; }
    &.complete>.content>section{
        &>p, &>h1, &>h2, &>h3, &>h4, &>h5{ color: $secondary-green !important; }
    } 
    &.incomplete>.content>section{
        &>p, &>h1, &>h2, &>h3, &>h4, &>h5{ color: $secondary-red !important; }
    } 
    &.info>.content>section{
        &>p, &>h1, &>h2, &>h3, &>h4, &>h5{ color: $primary-grey !important; }
    } 
    &.warning>.content>section{
        &>p, &>h1, &>h2, &>h3, &>h4, &>h5{ color: $secondary-yellow !important; }
    } 
    &.blank{
        .icon{
            border: $border;
            border-radius: 100%;
        }
        &>.content>section{ 
            &>p, &>h1, &>h2, &>h3, &>h4, &>h5{ color: $primary-grey; }
        }
    }
}