@import "./settings/variables";
@import "./settings/mixins";
@import "./settings/responsive";
@import "./settings/animation";
@import "./base/table";
@import "./base/datepicker";
@import "./modules/achievement";
@import "./modules/image-editor";

.modal {
    &.transaction {
        overflow: auto;
        height: 100%;
        padding: 2rem 4rem!important;
        h2 {
            font-weight: $font-weight-bold;
            margin-bottom: 1.5rem;
        }
        h3{
            font-weight: $font-weight-bold;
            margin-bottom: 1.5rem;
            white-space: wrap;
            padding: 0 2rem;
            small {
                font-size: $font-XS;
                margin-top: 8px;
            }
        }
        .message {
            font-weight: $font-weight-regular;
            h3 {
                font-weight: inherit;
                margin: 0;
                padding: 0;
            }
            span {
                font-weight: $font-weight-bold;
            }
            div {
                color: $primary-grey;
                text-align: right;
                margin-top: 1.5rem;
            }
        }
        .divider {
            margin: 1rem 0;
        }
        .status,
        .status-details,
        .message,
        .sender {
            text-align: center;
            span{
                color: inherit;
            }
            .red{ color: $secondary-red!important; }
            .blue{ color: $primary-blue!important; }
            .green { color: $primary-green!important; }
            .yellow { color: $secondary-yellow!important; }
        }
        .highlight {
            text-decoration: underline;
            color: $primary-blue!important;
        }
        table {
            width: 100%;

            tr {
                &.spacer{
                    height: $font-S;
                }
                td {
                    padding: 4px 0;
                    font-size: 11px;
                    line-height: $font-S;
                    color: $primary-grey;
                    white-space: nowrap;
                    span{
                        color: inherit;
                    }
                    &:first-child {
                        white-space: nowrap;
                        padding-right: 8px;
                    }
                    &:last-child {
                        padding-left: 8px;
                    }
                }
                &:first-child td {
                    padding-top: 0;
                }
                &:last-child td {
                    padding-bottom: 0;
                }
            }
        }
        button.button{
            margin: 40px auto 0;
            width: 256px;
            display: block;

            & + button.button {
                margin-top: 16px;
            }
        }
    }

    &.reset-password {
        .form {
            min-width: 350px;
        }
        h1 {
            margin: 2rem 0;
        }
    }
}
.container-grid {
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #ccc;
    margin: 20px 0px !important;
    width: 100% !important;
    &.user-info {
        padding: 1.5rem 2.25rem 0;
        p {
            vertical-align: middle;
            letter-spacing: normal;
            margin: 0!important;
            padding: 0.75rem 0;
            line-height: 20px;
        }
        button {
            font-size: $font-S;
            letter-spacing: normal;
            padding: 0 1.5rem;
            height: 30px;
            width: auto;
            max-width: 100%;
        }
        @media(min-width: 840px) {
            div:last-of-type {
                padding-left: 5rem;
            }
        }
        @media screen and (max-width: 839px) and (min-width: 540px) {
            div {
                padding-right: 2rem;
                p {
                    span {
                        display: block;
                    }
                }
            }
            div:last-of-type {
                padding-right: 0;
            }
        }
        @media(max-width: 539px) {
            div {
                max-width: 100%;
                flex-basis: 100%;
            }
        }
        @media(max-width: 320px) {
            div {
                p {
                    span {
                        display: block;
                    }
                }
            }
        }
    }
    &.individual {
        padding: 1.5rem 2.25rem;
        white-space: nowrap;
        h3 {
            color: $primary-grey;
        }
    }
}
.item-grid {
    padding: 20px !important;
    border-radius: 8px;
    border: 1px solid #ccc;
    margin-top: 20px !important;
    max-width: 63.3% !important;
    &.partial {
        max-width: 100% !important;
        @media (min-width: 769px) {
            &.MuiGrid-grid-md-8 {
                flex-basis: 63%;
            }
        }
        @media (min-width: 769px) {
            &.MuiGrid-grid-md-4 {
                flex-basis: 33%;
            }
        }
    }
    &.inner {
        max-width: calc(100% + 5px) !important;
        margin: 10px -5px !important;
        padding: 10px 18px !important;
        margin-top: 0 !important;
    }
}

.MuiGrid-spacing-xs-1 {
    padding: 4px !important;
    & .item-grid.partial.MuiGrid-grid-md-6 {
        max-width: calc(100% - 2px) !important;
        flex-basis: calc(50% - 8px);
        & h3 {
            line-height: 36px;
        }
        p {
            margin-bottom: 0;
        }
    }
}
.standard-grid {
    padding: 10px;
    .average-sales {
        padding-left: 2rem;
    }
    .bar-graph {
        padding: 0.5rem 0;
        .MuiLinearProgress-colorPrimary {
            background-color: $primary-lightergrey;
        }
        &.credit-debit-card > .MuiLinearProgress-colorPrimary > .MuiLinearProgress-barColorPrimary {
            background-color: $primary-blue;
        }
        &.bank-transfer > .MuiLinearProgress-colorPrimary > .MuiLinearProgress-barColorPrimary {
            background-color: $primary-green;
        }
        &.online-banking > .MuiLinearProgress-colorPrimary > .MuiLinearProgress-barColorPrimary {
            background-color: $secondary-yellow;
        }
        &.e-wallet-crypto > .MuiLinearProgress-colorPrimary > .MuiLinearProgress-barColorPrimary {
            background-color: $secondary-red;
        }
        &.over-the-counter > .MuiLinearProgress-colorPrimary > .MuiLinearProgress-barColorPrimary {
            background-color: $primary-black;
        }
        &.others > .MuiLinearProgress-colorPrimary > .MuiLinearProgress-barColorPrimary {
            background-color: #3b9fa7;
        }
    }
}
.user-dashboard {
    .signup {
        .divider {
            padding: 0;
        }
    }
    .blur {
        -webkit-filter: blur(5px);
        -moz-filter: blur(5px);
        -o-filter: blur(5px);
        -ms-filter: blur(5px);
        filter: blur(5px);
    }
    .from-date,
    .to-date {
        visibility: hidden;
        width: 100%;
    }
    .divider {
        @media(min-width: 1081px) {
            padding: 0 125px;
        }
        @media(max-width: 1080px) {
            padding: 0 100px;
        }
        @media(max-width: 720px) {
            padding: 0 50px;
        }
        @media(max-width: 719px) {
            padding: 0;
        }
        padding: 0 25px;
    }
    .reports {
        width: 100%;
        .overview-footer {
            text-align: center;
            margin: 1.5rem 0;
        }
        .filter-button {
            position: relative;
            margin-right: 8px;
            button {
                margin: 0;
            }
            .calendar.hide {
                display: none;
            }
            .calendar {
                width: fit-content;
                position: absolute;
                top: 37px;
                left: 0;
                z-index: 1000;
                padding: 0;
            }
        }
    }
    a {
        text-decoration: none;
    }
    .overview {
        .user-info {
            p > span {
                color: $primary-grey;
                font-weight: $font-weight-bold;
            }
        }
    }
    .my-account {
        .divider {
            padding: 0;
        }
        .footer-notes {
            width: 100%;
            text-align: center;
            small {
                display: inline;
                text-align: center;
                letter-spacing: normal;
                font-size: $font-XS;
            }
        }
        .footer {
            border: none;
        }
    }
    .customizations {
        .divider {
            padding: 0;
        }
        .footer-notes {
            width: 100%;
            text-align: center;
            small {
                display: inline;
                text-align: center;
                letter-spacing: normal;
                font-size: $font-XS;
            }
        }
        .footer {
            border: none;
        }
        .input-box {
            margin: 1.5rem 0;
        }
        .input-group {
            margin: 1.5rem 0;
            label {
                position: relative;
                line-height: 18px;
                min-height: 18px;
                font-weight: 600;
            }
            .input-box,
            .preview,
            input,
            select,
            small {
                font-size: $font-S;
                margin: 0;
                line-height: 2rem;
            }
            .radio-group {
                width: 100%;
                display: flex;
                justify-content: space-between;
                margin-bottom: 0.5rem;
            }
            .input-prepend{
                &:before, .input-icon {
                    width: 30px;
                    height: 30px;
                }
            }
            .input-box.radio-field {
                width: auto;
            }
            input[type=radio] {
                margin: 3px 5px;
            }
            & > .input-box.full-width {
                .input-prepend {
                    input, select, .preview {
                        border-radius: 4px 4px 0 0;
                    }
                }
            }
            & > .input-box.full-width ~ .input-box.full-width {
                .input-prepend {
                    input, select, .preview {
                        border-radius: 0;
                    }
                }
                &:last-of-type {
                    .input-prepend {
                        input, select, .preview {
                            border-bottom-right-radius: 4px;
                            border-bottom-left-radius: 4px;
                        }
                    }
                }
            }
        }
        .multi-input-category-container {
            margin: 1.5rem 0;
            .category {
                box-shadow: none;
                &:before {
                    background-color: inherit;
                }
            }
            .category-header {
                .title {
                    display: block;
                    margin : 8px 0;
                }
                .btn-container {
                    padding-left: 1rem
                }
            }
            .category-row {
                display: flex;
                padding: 1.5rem 1rem;
                .category-image {
                    flex-grow: 0;
                    .preview {
                        overflow: hidden;
                    }
                }
                .category-fields-container {
                    margin-left: 1rem;
                    flex-grow: 1;
                    .input-box {
                        .input-prepend {
                            input {
                                border-radius: 0;
                            }
                        }
                    }
                    .input-box:first-of-type {
                        .input-prepend {
                            input {
                                border-radius: 4px 4px 0 0;
                            }
                        }
                    }
                    .input-box:last-of-type {
                        .input-prepend {
                            input {
                                border-radius: 0 0 4px 4px;
                            }
                        }
                    }
                }
                &.Mui-focusVisible {
                    background-color: transparent;
                }
                .checkbox-container {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
            }
            .category-details, .biller-details {
                width: 100%;
                display: flex;
                flex-direction: column;
                .MuiCircularProgress-root {
                    margin: auto;
                }
            }
            .biller {
                vertical-align: middle;
                line-height: normal;
                .switch, .toggle-switch {
                    margin: 0;
                }
                &-summary {
                    box-shadow: inherit;
                }
                .accordion-container {
                    text-align: center;
                }
                .update-button {
                    width: 200px;
                    margin: 1rem auto;
                }
            }
            .input-group {
                margin: 1rem 0;
                input, select, label {border-radius: 0}
                .input-box {
                    flex: 0 0 50%;
                }
                .input-box:not(.radio-field) {
                    &:first-of-type {
                        input, label, select {
                            border-top-left-radius: 4px;
                        }
                        &.full-width {
                            input, label, select {
                                border-top-right-radius: 4px;
                            }
                        }
                    }
                    &:nth-of-type(2) {
                        input, label, select {
                            border-top-right-radius: 4px;
                        }
                    }
                    &:nth-last-of-type(2) {
                        input, label, select {
                            border-bottom-left-radius: 4px;
                        }
                    }
                    &:last-of-type {
                        input, label, select {
                            border-bottom-right-radius: 4px;
                        }
                    }
                    &:only-of-type {
                        input, label, select {
                            border-radius: 4px;
                        }
                    }
                    &.full-width {
                        flex: 0 0 100%;
                        input, label, select {
                            border-bottom-left-radius: 0;
                        }
                        &:last-of-type {
                            input, label, select {
                                border-radius: 0 0 4px 4px;
                            }
                        }
                        &:only-of-type {
                            input, label, select {
                                border-radius: 4px;
                            }
                        }
                        & ~ .full-width:not(:first-of-type):not(:last-of-type) {
                            input, label, select {
                                border-top-right-radius: 0;
                            }
                        }
                    }
                    & ~ .input-box:not(:first-of-type):not(:last-of-type) {
                        input, label, select {
                            border-top-left-radius: 0;
                            border-bottom-right-radius: 0;
                        }
                    }
                    &.biller-logo {
                        flex: 0 0 35%;
                        input, label, select {
                            border-radius: 4px;
                        }
                    }
                    &.biller-bg {
                        flex: 0 0 60%;
                        input, label, select {
                            border-radius: 4px;
                        }
                    }
                }
            }
            .add-remove-field {
                display: flex;
                justify-content: flex-end;
                padding: 1rem 0.5rem;
                .button {
                    margin-right: 0.5rem;
                }
            }
            .parameter-field {
                display: flex;
                margin-bottom: 1rem;
                .input-prepend {
                    text-align: center;
                }
                .input-group, .radio-group {
                    justify-content: space-around;
                    margin: 0;
                }
                .checkbox-container {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    padding-top: 2.5rem;
                }
                .button {
                    margin-top: 1rem;
                    width: 100%;
                }
            }
            .image-upload {
                width: 80px;
                height: 80px;
                margin: auto;
                .input-prepend, label {
                    height: 100%;
                }
                img {
                    width: 100%;
                }
            }
            .text-field {
                width: 100%;
            }
        }
    }
    .settings {
        text-align: left;
        .divider {
            padding: 0;
        }
        label {
            font-size: $font-S!important;
        }
        .setting {
            p {
                margin-bottom: 1rem;
            }
            margin-bottom: 2rem!important;
            .input-box {
                margin: 0 1rem;
                small {
                    height: fit-content;
                    min-height: fit-content;
                }
            }
            a {
                text-decoration: underline;
            }
        }
        .container-grid {
            padding: 1.5rem 1rem;
        }
        .your-acount {
            text-align: left;
            span {
                color: $primary-black;
                &.bold {
                    font-weight: $font-weight-bold;
                }
            }
            .italic {
                font-style: italic;
            }
            p {
                white-space: nowrap;
            }
            .divider {
                margin: 1rem 0;
            }
        }
        .two-fa,
        .sms-notification,
        .payment-method,
        .fulfillment-method {
            p {
                margin-bottom: 1rem;
            }
            .input-box {
                margin: 0 1rem;
            }
        }
    }
    .analytics {
        .line-graph {
            text-align: center;
            .dataTables_filter {
                & > section {
                    padding: 0 1.5rem;
                }
                .date-filter-1 {
                    border-right: 1px solid #ccc;
                }
                .date-filter-2 {
                    span {
                        padding: 0 0.5rem;
                    }
                }
            }
            .standard-grid {
                text-align: left;
            }
            .recharts-responsive-container {
                margin: 0 -3.5rem;
            }
        }
    }
    .bar-chart {
        .date-filter-1 > .filter-button {
            margin: 0 0.25rem;
        }
        .recharts-responsive-container {
            margin: 0 -3.5rem;
        }
    }
    .icon-date-from,
    .icon-date-to {
        &::before,
        &::after {
            width: 20px!important;
            height: 20px!important;
            z-index: -1;
        }
    }
    & > header {
        box-sizing: border-box;
        width: 100%;
        max-width: 800px;
        margin: 1rem auto;
        text-align: center;

        h2{
            margin-bottom: 1rem;
            line-height: 1;
        }
        .avatar {
            height: 96px;
            width: 96px;
            margin: 0 auto 1rem;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .tab-links {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            div {
                width: 100%;
                @media(min-width: 280px) {
                    width: 50%;
                }
                @media(min-width: 360px) {
                    width: 33.33%;
                }
                @media(min-width: 650px) {
                    width: auto;
                    margin: 16px;
                }
                @include breakpointByScreenType(mobile) {
                    &::first-child {
                        margin-top: 0;
                    }
                    &::last-child {
                        margin-bottom: 0;
                    }
                }
                cursor: pointer;
                font-size: $font-S;
                color: $primary-grey;

                a {
                    color: inherit;
                    font-size: $font-S;
                }
                &.hover,
                &.active {
                    color: $primary-blue;
                }
                &.disabled {
                    color: $primary-lightgrey;
                    cursor: not-allowed;
                }
            }
        }
    }
    .panels {
        .settings {
            & > h4 {
                text-align: left;
                margin-bottom: 24px;
                & + a > button {
                    margin-top: 0;
                }
            }
            .footer {
                border: none;
            }
        }
        .pending-account-header {
            color: $primary-blue;
            font-weight: bold;
            text-align: center;
            margin: 2.5rem 0;
        }
    }
    .report {
        .dataTables
        {
            &_scrollHead {
                overflow: hidden;
                position: relative;
                border  : 0;
                width   : 100%
            }
            &_scrollHeadInner {
                box-sizing   : content-box;
                width        : 1200px;
                padding-right: 0;
            }
            &_scrollBody {
                position: relative;
                overflow: auto;
                width: 100%;
            }
        }
        & .no-footer {
            margin-left: 0;
            width: 1200px;
        }
    }
    .footer {
        .button {
            margin: 40px auto 0;
            width: 280px;
            display: block;
        }
    }
}
.modal-container {
    .MuiDialog-paperWidthSm {
        max-width: 100vw;
    }
}
.modal.multi-input-add-modal {
    text-align: center;
    padding: 2.5rem 2rem;
    .button {
        width: 135px;
        &.save-button {
            width: 250px
        }
    }
    &.larger_modal {
        min-width: 750px;
    }
    .input-box {
        margin: 3rem 0;
    }
    .input-group {
        .input-box,
        .preview,
        input,
        select,
        small {
            font-size: $font-S;
            margin: 0;
            line-height: 2rem;
        }
        .radio-group {
            width: 100%;
            display: flex;
            justify-content: space-between;
            margin-bottom: 0.5rem;
        }
        .input-prepend{
            &:before, .input-icon {
                width: 30px;
                height: 30px;
            }
        }
        .input-box.radio-field {
            width: auto;
        }
        input[type=radio] {
            margin: 3px 5px;
        }
    }
    .category {
        text-align: right;
        box-shadow: none;
        &:before {
            background-color: inherit;
        }
    }
    .category-header {
        .title {
            display: block;
            margin : 8px 0;
        }
        .btn-container {
            padding-left: 1rem
        }
    }
    .subcategory-row {
        display: flex;
        padding: 1.5rem 0;
        margin-bottom: 1rem;
        border-bottom: dashed $primary-lightgrey;
        .checkbox-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-right: 0.75rem;

            input[type=radio] {
                margin: 0
            }
        }
        .input-box {
            margin-bottom: 0!important;
        }
        .category-field {
            margin-top: 1.25rem!important;
        }
        .biller-logo {
            margin-right: 0.5rem;
            label {
                height: 85px;
            }
        }
        .biller-bg {
            margin-left: 0.5rem;
            label {
                height: 85px;
            }
        }
    }
    .category-row {
        display: flex;
        padding: 1.5rem 0.5rem;
        margin-bottom: 1rem;
        &:last-of-type {
            border-bottom: dashed $primary-lightgrey;
        }
        .category-image {
            flex-grow: 0;
        }
        .category-fields-container {
            margin-left: 1rem;
            flex-grow: 1;
        }
        &.Mui-focusVisible {
            background-color: transparent;
        }
        .checkbox-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-right: 0.75rem;

            input[type=radio] {
                margin: 0
            }
        }
    }
    .category-details, .biller-details {
        width: 100%;
        display: flex;
        flex-direction: column;
        .MuiCircularProgress-root {
            margin: auto;
        }
    }
    .image-upload {
        width: 80px;
        height: 80px;
        margin: auto;
        .input-prepend, label {
            height: 100%;
        }
        img {
            width: 100%;
        }
    }
    .text-field {
        width: 100%;
    }
}
.success {
    min-width: 300px;
    p {
        margin: 2rem 0;
    }
}
.customizations-success-modal {
    .content {
        p {
            margin: 2rem 0;
        }
    }
    @media (min-width: 500px) {
        min-width: auto;
    }
}
.upgrade-button {
    position: absolute!important;
    padding: 0 1.5rem!important;
    height: 30px!important;
    width: auto!important;
    letter-spacing: normal!important;
    font-size: 14px;
    max-width: 100%;
    top: calc(50% - 20px);
    left: calc(50% - 60px);
}
.jpt-loader {
    width: 100%;
    height: 100%;
    div {
        position: absolute;
        top: calc(50% - 36px);
        left: calc(50% - 36px);
    }
}
.jpt-payment-frame {
    max-width: 100%!important;
    max-height: 100%!important;
}