.toggle-switch {
    text-align: left;
    width: 100%;
    margin-bottom: 24px;

    header {
        @include flex(justify, space-between);
        align-items: center;
        h4 { margin: 0; }
        label {
            position: relative;
            width: 70px;
            height: 24px;

            input {
                opacity: 0;
                width: 0;
                height: 0;

                &:checked + div {
                    background-color: $primary-blue;

                    &:before { transform: translate(100%, -50%); }
                }
                // &:focus + div { border: 1px solid $primary-blue; }
            }
            div {
                @include position(absolute, 0 0 0 0);
                display: flex;
                box-sizing: border-box;
                cursor: pointer;
                border: 2px solid $primary-lightgrey;
                background-color: $primary-lightgrey;
                transition: .25s;
                border-radius: 4px;

                span.on {
                    font-size: smaller;
                    color: $primary-purewhite;
                    margin: auto 0.35rem;
                }

                span.off {
                    font-size: smaller;
                    color: $primary-black;
                    margin: auto 0.4rem;
                }

                &.disabled { cursor: not-allowed; }
                &:before {
                    @include position(absolute, 50% n n n);
                    content: "";
                    transform: translate(0, -50%);
                    height: 100%;
                    width: 50%;
                    background-color: $primary-purewhite;
                    transition: .25s;
                    border-radius: 2px;
                }
            }
        }
    }
    p { margin-top: 12px; }
}
.switch {
    text-align: left;
    width: 100%;
    margin-bottom: 24px;

    header {
        @include flex(justify, space-between);
        align-items: center;
        h4 { margin: 0; }
        label {
            position: relative;
            width: 40px;
            height: 24px;

            input {
                opacity: 0;
                width: 0;
                height: 0;

                &:checked + div {
                    background-color: $primary-blue;

                    &:before { transform: translate(16px, -50%); }
                }
                &:focus + div { border: 1px solid $primary-blue; }
            }
            div {
                @include position(absolute, 0 0 0 0);
                box-sizing: border-box;
                cursor: pointer;
                background-color: $primary-lightgrey;
                transition: .25s;
                border-radius: 24px;

                &.disabled { cursor: not-allowed; }
                &:before {
                    @include position(absolute, 50% n n 2px);
                    content: "";
                    transform: translate(0, -50%);
                    height: 20px;
                    width: 20px;
                    background-color: $primary-purewhite;
                    transition: .25s;
                    border-radius: 50%;
                }
            }
        }
    }
    p { margin-top: 12px; }
}
